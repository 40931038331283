.chart {
    width: 100%;
    text-align: center;
    position: relative;
}

.chart .recharts-wrapper {
    margin: auto;
}

table.full-width {
    width: 100%;
}

.truncate {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    max-width: 400px;
}

.truncate-short {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    max-width: 200px;
}

ul.pager li {
    display: inline;
    padding: 0 5px;
}

ul.pager li a {
    color: #333;
}